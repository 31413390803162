// @flow

import adminRequest from 'services/adminRequest';
import type { SpeedDetection } from 'types';

// Configuration Du Vehicule
//* ******************************************//

/**
 * Request configuration Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 */
export function retrieveConfigByEquipementId(token: string, equipementId: number): Promise<> {
    const url = `vehicles/${equipementId}/configuration`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Request Module per Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 */
export function retrieveModuleByEquipementId(token: string, equipementId: number): Promise<> {
    const url = `vehicles/${equipementId}/module`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Request settings Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 */
export function retrieveSettingsByEquipementId(token: string, equipementId: number): Promise<> {
    const url = `vehicles/${equipementId}/settings`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Edit SpeedDetection Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {SpeedDetection} data
 */
export function editSpeedDetectionByEquipementId(
    token: string,
    equipementId: number,
    data: SpeedDetection
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/speed-detection`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit GarageMode Config by Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {SpeedDetection} data
 */
export function editGarageModeByEquipementId(
    token: string,
    equipementId: number,
    data: SpeedDetection
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/garage-mode`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        false,
        token
    );
}

/**
 * Reset Engine Detection Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editEngineDetectionByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/engine-detection`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                isQualificationRequired: data,
                keyOnDecivolt: 0,
                startupDecivolt: 0,
                runningDecivolt: 0,
            },
        },
        false,
        token
    );
}

/**
 * Edit EngineCutoff Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editEngineCutoffByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/engine-cutoff`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit EngineCutoff Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editAdditionalDataByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/additional-data`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit EngineCutoff Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editEquipementNameByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: { name: data },
        },
        false,
        token
    );
}

/**
 * Edit CapteursTemp Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editCapteursTempByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/temperature-alert-threshold`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        false,
        token
    );
}

/**
 * Edit Auxiliaire Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editAuxiliaireByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/auxiliary-names`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit AccelerometreDetection Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editAccelerometreDetectionByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/acceleration-detection`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit AccelerometreDetection Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editNotifCourrielByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/email-notification`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        false,
        token
    );
}

/**
 * Edit AccelerometreDetection Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editCardReaderConfigByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/card-reader`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        false,
        token
    );
}

/**
 * Edit LowPowerMode Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editLowPowerModeByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/low-power-mode`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit LowPowerMode Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editFardierConfigByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/fardier`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit Connexion Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editConnexionByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/server-communication`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit DepNonAutorise Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editDepNonAutoriseByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/unauthorized-move-schedule`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit Engine Events Config Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editEngineEventsByEquipementId(
    token: string,
    equipementId: number,
    data: *
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/engine-events`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        false,
        token
    );
}

// Déplacement Non-Autorisé

/**
 * Gets the unauthorized move schedule settings
 *
 * @param {string} token
 * @param {number} equipementId
 */
export function retrieveUnauthorizedMoveConfigByEquipementId(
    token: string,
    equipementId: number
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/unauthorized-move-schedule`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

// Notification Email
//* ******************************************//

/**
 * Request Email Notification config by equipement.
 *
 * @param {string} token
 * @param {number} equipementId
 */
export function retrieveEmailNotifConfigByEquipementId(
    token: string,
    equipementId: number
): Promise<> {
    const url = `vehicles/${equipementId}/configuration/email-notification`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Request Emails by Branch.
 *
 * @param {string} token
 * @param {number} branchId
 */
export function retrieveEmailsByBranchId(token: string, branchId: number): Promise<> {
    const url = `branches/${branchId}/emails`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token,
    );
}

/**
 * Add Emails by Branch.
 *
 * @param {string} token
 * @param {number} branchId
 * @param {string} data
 */

export function addEmailsByBranchId(token: string, branchId: number, data: string): Promise<> {
    const url = `branches/${branchId}/emails`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        false,
        token,
    );
}

/**
 * Delete Emails by Branch.
 *
 * @param {string} token
 * @param {number} branchId
 * @param {number} emailId
 */
export function deleteEmailsByBranchId(token: string, branchId: number, emailId: number): Promise<> {
    const url = `branches/${branchId}/emails/${emailId}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        true,
        token,
    );
}

/**
 * Update Emails by Branch.
 *
 * @param {string} token
 * @param {number} branchId
 * @param {number} emailId
 * @param {string} data
 */
export function updateEmailsByBranchId(token: string, branchId: number, emailId: number, data: string): Promise<> {
    const url = `branches/${branchId}/emails/${emailId}`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        true,
        token,
    );
}


// Configuration Moteur
//* ******************************************//

// Installation
//* ******************************************//
