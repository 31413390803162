// @flow

export const GET_CONFIG_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_CONFIG_BY_EQUIPEMENT_SUCCESS';
export const GET_CONFIG_ERROR = 'app/services/Config/GET_CONFIG_ERROR';
export const GET_SETTINGS_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_SETTINGS_BY_EQUIPEMENT_SUCCESS';
export const GET_MODULE_BY_EQUIPEMENT_SUCCESS =
    'app/services/Config/GET_MODULE_BY_EQUIPEMENT_SUCCESS';
export const GET_MODULE_ERROR = 'app/services/Config/GET_MODULE_ERROR';
export const LOADING_CONFIG = 'app/services/Config/LOADING_CONFIG';
export const LOADING_EMAILS = 'app/services/Config/LOADING_EMAILS';
export const GET_EMAIL_NOTIF_CONFIG_SUCCESS = 'app/services/Config/GET_EMAIL_NOTIF_CONFIG_SUCCESS';
export const GET_EMAILs_BRANCH_SUCCESS = 'app/services/Config/GET_EMAILs_BRANCH_SUCCESS';
export const GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS =
    'app/services/Config/GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS';
export const DISMISS_CONFIG_ERROR = 'app/services/Config/DISMISS_CONFIG_ERROR';
export const DISMISS_CONFIG_LOADING = 'app/services/Config/DISMISS_CONFIG_LOADING';

export const receivedGetConfigByEquipementIdSuccess = (ConfigRecords): GenericActionType => ({
    type: GET_CONFIG_BY_EQUIPEMENT_SUCCESS,
    ConfigRecords,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING_CONFIG,
});

export const dismissConfigLoading = (): GenericActionType => ({
    type: DISMISS_CONFIG_LOADING,
});

export const startLoadingEmails = (): GenericActionType => ({
    type: LOADING_EMAILS,
});

export const receivedConfigError = (): GenericActionType => ({
    type: GET_CONFIG_ERROR,
});

export const receivedGetSettingsByEquipementIdSuccess = (SettingsRecords): GenericActionType => ({
    type: GET_SETTINGS_BY_EQUIPEMENT_SUCCESS,
    SettingsRecords,
});

export const receivedGetModuleByEquipementIdSuccess = (ModuleRecords): GenericActionType => ({
    type: GET_MODULE_BY_EQUIPEMENT_SUCCESS,
    ModuleRecords,
});

export const receivedGetEmailNotifConfigByEquipementId = (
    EmailNotifConfigRecords
): GenericActionType => ({
    type: GET_EMAIL_NOTIF_CONFIG_SUCCESS,
    EmailNotifConfigRecords,
});

export const receivedUnauthorizedMoveConfigByEquipementId = (
    UnauthorizedMoveRecords
): GenericActionType => ({
    type: GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS,
    UnauthorizedMoveRecords,
});

export const receivedGetEmailsByBranchId = (Emails): GenericActionType => ({
    type: GET_EMAILs_BRANCH_SUCCESS,
    Emails,
});

export const receivedModuleError = (): GenericActionType => ({
    type: GET_MODULE_ERROR,
});

export const receivedDissmissedConfigError = (): GenericActionType => ({
    type: DISMISS_CONFIG_ERROR,
});
