// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectConfigState = () => (state) => state.get('config');

const selectConfigRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('ConfigRecords'));

const selectSettingsRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('SettingsRecords'));

const selectModuleRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('ModuleRecords'));

const selectEmailNotifConfigRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('EmailNotifConfigRecords'));

const selectEmailsRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('Emails'));

const selectUnauthorizedMoveRecords = () =>
    createSelector(selectConfigState(), (substate) => substate.get('UnauthorizedMoveRecords'));

const selectConfigLoading = () =>
    createSelector(selectConfigState(), (substate) => substate.get('loading'));

const selectEmailsLoading = () =>
    createSelector(selectConfigState(), (substate) => substate.get('loadingEmails'));

const selectConfigError = () =>
    createSelector(selectConfigState(), (substate) => substate.get('configError'));

export {
    selectConfigRecords,
    selectModuleRecords,
    selectSettingsRecords,
    selectConfigLoading,
    selectEmailsRecords,
    selectEmailNotifConfigRecords,
    selectUnauthorizedMoveRecords,
    selectEmailsLoading,
    selectConfigError,
};
