// @flow

import requestDAPI from 'services/requestDAPI';
import adminRequest from "../adminRequest";

/**
 * Request Drivers vehicles.
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} sort
 * @param {string} order
 */
export function retrieveDriversByVehicle(
    moduleNumber: string,
    token: string,
    page: number
): Promise<> {
    // this Function Just for test
    return requestDAPI(
        `drivers?ModuleNumber=${moduleNumber}&api_token=${token}&page=${page}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function updateAuthorizationsByVehicleName(data: Driver, token: string): Promise<> {
    // this Function Just for test
    return requestDAPI(
        `authorizationsByModuleNumber?api_token=${token}`,
        {
            method: 'POST',
            data: {
                authorization: { ...data },
            },
        },
        true,
        token
    );
}

export function resetDriverCards(moduleNumber: string, token: string): Promise<> {
    // this Function Just for test
    return requestDAPI(
        `reset?ModuleNumber=${moduleNumber}&api_token=${token}`,
        {
            method: 'POST',
        },
        true,
        token
    );
}

export function addDriver(data: any, token: string): Promise<> {
    return requestDAPI(
        `drivers?api_token=${token}`,
        {
            method: 'POST',
            data: data,
        },
        true,
        token
    );
}

export function retrieveDrivers(token: string): Promise<> {
    return requestDAPI(
        `authorizations?api_token=${token}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function deleteDriver(data: number,token: string): Promise<> {
    return requestDAPI(
        `drivers/${data}?api_token=${token}`,
        {
            method: 'DELETE',
            data
        },
        false,
        token
    );
}

export function retrieveAllDrivers(token) {
    return requestDAPI(
        `allDrivers?api_token=${token}`,
        {
            method: 'GET'
        },
        false,
        token
    );
}


/**
 * Request Drivers Score Card.
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} branchId
 * @param {*} data
 */
export function retrieveDriversScoreCard (token: string, locale: string,branchId: number,  data: * ): Promise<> {
    const url = `branches/${branchId}/driver-score-card`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data: data,
        },
        false,
        token
    );
}
/* Add driver authorization to all vehicle or add all drivers to a specific vehicle */
export function authorizeAll(data: any, token: string): Promise<> {
    return requestDAPI(
        `authorizeAll?api_token=${token}`,
        {
            method: 'POST',
            data: data,
        },
        true,
        token
    );
}