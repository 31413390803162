// @flow

import { push } from 'react-router-redux';

import {
    receivedOrganizationsSuccess,
    receivedOrganizationDataSuccess,
    receivedOrganizationUsersSuccess,
    receivedOrganizationDataSuccessNotFormat,
    receivedEditOrganizationSuccess,
    receivedDeleteOrganizationSuccess,
    receivedDeleteOrganizationEquipment,
    receivedOrganizationError,
    receivedOrganizationExtraUsersSuccess,
    receivedOrganizationExtraUsersError,
    startLoading,
} from './actions';

import {
    retrieveOrganizations,
    retrieveOrganizationBranches,
    retrieveOrganizationUsers,
    retrieveOrganization,
    retrieveOrganizationEquipment,
    createOrganizationRequest,
    editOrganizationRequest,
    deleteOrganizationRequest,
    retrievebranchesData,
    retrieveOrganizationParent,
    editBranchRequestByField,
} from './resources';

// import { getRolesRequest } from 'services/User/resources';
import { closeModal } from 'services/Modal/thunks';
import { parseJWT } from 'helpers';
import { fromJS } from 'immutable';
import type {
    ReduxDispatch,
    ResponseErrorType,
    ImmutableMap,
    UserDataType,
    Organization,
} from 'types';
import { RepeatOneSharp } from '@material-ui/icons';

export const fetchOrganizations = (page: number = 1, query: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const userToken = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return retrieveOrganizations(page, userToken, locale, query)
        .then((response: Object) => {
            response.data.meta = {};
            response.data.meta.currentPage = page;
            response.data.meta.lastPage = Math.ceil(response.data.totalCount / 10);
            dispatch(receivedOrganizationsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedOrganizationError(error));
        });
};
export const getOrganizationExtraUsers = (branchId: number, page: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    dispatch(startLoading());
    
    return retrieveOrganizationUsers(branchId, token, locale, page)
    .then( (response) => {
        dispatch(receivedOrganizationExtraUsersSuccess(response.data.users));
    }
    )
    .catch((error) => {
        dispatch(receivedOrganizationExtraUsersError(error));
    });

}
export const getOrganizationData = (branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    dispatch(startLoading());
    return Promise.all([
        retrieveOrganization(branchId, token, locale),
        retrieveOrganizationUsers(branchId, token, locale, 1),
        retrieveOrganizationEquipment(branchId, token, locale),
        // getRolesRequest(token),
    ])
        .then(async (responses) => {
            // const totalCount = responses[1].data.totalCount;
            // const numberOfUsersRequests = parseInt(totalCount / 50) ;
            // var page = 2;
            const organization = {
                organization: responses[0].organization,
                branches: responses[0].children,
                users: responses[1].data.users,
                usersCount: responses[1].data.totalCount,
                equipment: responses[2].data.vehicles,
                // roles: responses[3].data,
            };
            if (organization.organization.parentId) {
                retrieveOrganizationParent(organization.organization.parentId, token).then(
                    (parent) => {
                        organization.parent = parent;
                        dispatch(receivedOrganizationDataSuccess(organization));
                    }
                );
            } else {
                dispatch(receivedOrganizationDataSuccess(organization));
                // for (let i = 0; i < numberOfUsersRequests ; i++) {
                //     let secondsDifference =  500*(i+1);
                //     setTimeout(() => {
                //         retrieveOrganizationUsers(branchId, token, locale, page).then((res) => {
                //             dispatch(receivedOrganizationUsersSuccess(res.data.users));
                            
                //         }).catch((error) => {
                //             console.log(error);
                //         })
                //         page = page + 1;
                //     },secondsDifference)
                // }
            }
            dispatch(closeModal());
        }
        )
        .catch((error) => {
            // if error retreiving organizaiton data, (not found / deleted) return to the organizaitons list page.
            if (error && error.status === 404) {
                dispatch(push(`/${locale}/interne/orgs`));
            }
        });
};

/**
 *
 */
export const getBranchData = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    dispatch(startLoading());
    return retrievebranchesData(branchId, token)
        .then((branch) => {
            dispatch(receivedOrganizationDataSuccess(branch));
            dispatch(receivedOrganizationDataSuccessNotFormat(branch));
        })
        .catch((error) => {
            dispatch(receivedOrganizationError(error));
        });
};



export const getBranchInfo = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    dispatch(startLoading());
    return retrievebranchesData(branchId, token)
        .then((branch) => {
            return branch;

        })
        .catch((error) => {
            dispatch(receivedOrganizationError(error));
        });
};

export const createOrganization = (data: Organization) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return createOrganizationRequest(data, token, locale)
        .then((response) => {
            if (data.parentId) {
                dispatch(getOrganizationData(data.parentId));
            } else {
                // TODO LATER => Redirect to created ORG if API gets changed
                // dispatch(push(`/${locale}/interne/orgs/${}`));
                dispatch(fetchOrganizations());
            }
        })
        .catch((err) => {
            dispatch(receivedOrganizationError(err));
        });
};

export const organizationError = (error: any) => (dispatch: ReduxDispatch) => {
    dispatch(receivedOrganizationError(error));
    return Object.keys(error).length === 0;
};

export const editOrganization = (data: Organization, organizationId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return editOrganizationRequest(organizationId, data, token, locale)
        .then((response) => {
            dispatch(receivedEditOrganizationSuccess(fromJS(data)));
        })
        .catch((err) => {
            dispatch(receivedOrganizationError(err));
        });
};

export const editBranch = (data: any, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return editOrganizationRequest(branchId, data, token, locale)
        .then((response) => {
            dispatch(receivedEditOrganizationSuccess(fromJS(data)));
        })
        .catch((err) => {
            dispatch(receivedOrganizationError(err));
        });
};

export const editBranchByField = (data: any, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return editBranchRequestByField(branchId, data, token)
        .then((response) => {
            dispatch(receivedEditOrganizationSuccess(fromJS(data)));
        })
        .catch((err) => {
            dispatch(receivedOrganizationError(err));
        });
};

export const deleteOrganization = (
    organizationId: number,
    deleteAction: string,
    parentId: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return deleteOrganizationRequest(organizationId, token).then((response) => {
        dispatch(receivedDeleteOrganizationSuccess());
        if (parentId) {
            dispatch(push(`/${locale}/interne/orgs/${parentId}`));
        }
    });
};

export const deleteOrganizationEquipment = (equipmentId: number) => (dispatch: ReduxDispatch) => {
    return dispatch(receivedDeleteOrganizationEquipment(equipmentId));
};
